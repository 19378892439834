<template>
  <div class="container">
    <m-l-g-menu />
    <div class="text-end p-3">
      <c-primary-button
        :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/crear`"
      >
        Crear MLE para
        {{ marcoLegalGeneralVersion.marco_legal_general.name }} ({{
          marcoLegalGeneralVersion.version
        }})
      </c-primary-button>
    </div>
    <c-card
      :title="`${marcoLegalGeneralVersion.marco_legal_general.name} (${marcoLegalGeneralVersion.version})`"
    >
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Marco legal específico</th>
            <th>Requerimiento</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="marco in marcosLegalesEspecificos" :key="marco.id">
            <td>{{ marco.name }}</td>
            <td>{{ marco.requerimiento }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :class="marco.tools_count ? 'text-info' : ''"
                :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${marco.id}/herramientas`"
                title="Herramientas"
                ><i class="fa-solid fa-list-ol"></i>
              </router-link>
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${marco.id}/editar`"
                title="Editar"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteMarcoLegalEspecifico(marco.id)"
                title="Eliminar"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import MLGMenu from "@/components/MarcoLegalGeneral/Menu.vue";
import { remove } from "@/services/marcos_legales_especificos";
import { showNotification } from "@/services/notification";
import {
  find,
  marcosLegalesEspecificos,
} from "@/services/marcos_legales_generales_versions";
export default {
  components: {
    CCard,
    CPrimaryButton,
    MLGMenu,
  },
  data() {
    return {
      marcoLegalGeneralVersionId: this.$route.params.versionId,
      marcoLegalGeneralId: this.$route.params.id,
      marcoLegalGeneralVersion: {
        version: "",
        marco_legal_general: {
          name: "",
        },
      },
      marcosLegalesEspecificos: [],
    };
  },
  mounted() {
    this.getMarcoLegalGeneralVersion();
  },
  methods: {
    getMarcoLegalGeneralVersion() {
      find(this.marcoLegalGeneralVersionId).then((response) => {
        this.marcoLegalGeneralVersion = response.data;
        this.getMarcosLegalesEspecificos();
      });
    },
    getMarcosLegalesEspecificos() {
      marcosLegalesEspecificos(this.marcoLegalGeneralVersionId).then(
        (response) => {
          this.marcosLegalesEspecificos = response.data;
        }
      );
    },
    deleteMarcoLegalEspecifico(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getMarcosLegalesEspecificos();
      });
    },
  },
};
</script>
